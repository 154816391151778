/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        if (typeof start !== 'undefined') {
          start();
        }

        // Mobile Menu
        $('.menu-hamburger').click(function(){
          $(this).toggleClass('active');
          $('.nav-primary').fadeToggle();
          $('body').toggleClass('body-lock');
        });

        $(window).resize(function(){
          if($(this).width() > 1023){
            $('.nav-primary').fadeIn();
            $('.menu-hamburger').removeClass('active');
          } else {
            $('.nav-primary').fadeOut();
            $('.menu-hamburger').removeClass('active');
          }
        });

        var dateToday = new Date(); 
        // Datepicker
        $('.datepicker').each(function(){
          $(this).datepicker({
            minDate: 0,
            yearRange: '0:+1',
            dateFormat: 'dd/mm/yy'
          });

          $(this).click(function(event){
            event.stopPropagation();
          });
        });

        function checkPlainSlider(){
          var slide = $('.slide-plain__wrap');
          if ( slide.length){
            slide.each(function(){
              var prev = $(this).parent().find('.slider-arrow-grey__prev');
              var next = $(this).parent().find('.slider-arrow-grey__next');
              $(this).slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                prevArrow: prev,
                nextArrow: next          
              });  
            });

          }
        } checkPlainSlider();

        function checkPreviewSlider(){
          var slide = $('.preview-slide__wrap');
          if ( slide.length){
            slide.each(function(){
              var prev = $(this).parent().find('.slider-arrow__prev');
              var next = $(this).parent().find('.slider-arrow__next');
              $(this).slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                prevArrow: prev,
                nextArrow: next          
              });  
            });

          }
        } checkPreviewSlider();

        function checkRatioSlider(){
          var slide = $('.slider-ratio__wrap');
          if(slide.length){
            slide.each(function(){
              var sliderNext = $(this).parent().find('.slider-arrow__next');
              var sliderPrev = $(this).parent().find('.slider-arrow__prev');
              $(this).slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                prevArrow: sliderPrev,
                nextArrow: sliderNext,
              });
            });
          }
        }checkRatioSlider();

        function checkBlurbSlider(){
          var slide = $('.slider-blurb__wrap');
          var prev = slide.parent().find('.slider-arrow-grey__prev');
          var next = slide.parent().find('.slider-arrow-grey__next');
          if(slide.length){
            slide.slick({
              dots: true,
              infinite: true,
              speed: 500,
              fade: true,
              cssEase: 'linear',
              prevArrow: prev,
              nextArrow: next          
            });
          }
        } checkBlurbSlider();

        function checkBlurbSliderAuto(){
          var slide = $('.slider-blurb__wrap-auto');
          var prev = slide.parent().find('.slider-arrow-grey__prev');
          var next = slide.parent().find('.slider-arrow-grey__next');
          if(slide.length){
            slide.slick({
              dots: true,
              infinite: true,
              speed: 500,
              fade: true,
              cssEase: 'linear',
              prevArrow: prev,
              nextArrow: next,
              autoplay: true,
              autoplaySpeed: 3000,
            });
          }
        } checkBlurbSliderAuto();

        function checkSideMenu(){
          var menu = $('.side-menu__wrap');
          if(menu.length){
            // Click 
            var extra = 20;
            function checkWidth(){
              var wWidth = $(window).width();
              if(wWidth > 1023){
                extra = 140;
              } else {
                extra = 20;
              }
            } checkWidth();

            $(window).resize(function(){
              console.log('test test')
              checkWidth();
            });

            $('.side-menu__each').each(function(){
              $(this).click(function(){
                var slug = $(this).data('slug');
                var divSlug;
                $('.activities-content').each(function(){
                  var test = $(this).data('slug');
                  if(slug === test){
                    divSlug = $(this);
                  }
                });
                console.log(divSlug.offset().top - extra, divSlug.offset().top, extra);
                $('html, body').animate({
                  scrollTop: divSlug.offset().top - extra
                }, 800);
                 
              });
            });
            // Scroll
            var lastScrollTop = 0;
            $(window).scroll(function(){
              var wTop = $(this).scrollTop();
              var menu = $('.side-menu__wrap');
              var menuParent = $('.side-menu-mobile').offset().top;
              var container = $('.side-menu-contain');
              var containerTotal = container.offset().top + container.height();
              var mTop = menu.offset().top;
              if (wTop > lastScrollTop){
                // downscroll code
                if(wTop >= (mTop - (20 + 140))){
                  menu.addClass('active');
                }

                if((wTop + (menu.height() + 280)) >= containerTotal){
                  menu.fadeOut('fast');
                }
              } else {
                // upscroll code
                if((menuParent - (20 + 140)) >= wTop){
                  menu.removeClass('active');
                }

                if((wTop + menu.height()) <= containerTotal){
                  menu.fadeIn();
                }
              }
              lastScrollTop = wTop;
            });

          }
        } checkSideMenu();

        function fadeBox(){
          var box = $('.fade-box__box');
          if(box.length){
            var current = $('.fade-box__menu-each').first().data('slug');
            var test = "[data-slug='" + current + "']";
            $('.fade-box__box').find(test).addClass('active');
            $('.fade-box__menu').find(test).addClass('active');
            $('.fade-box__menu-each').each(function(){
                $(this).click(function(){
                  var slug = $(this).data('slug');
                  // Menu
                  $('.fade-box__menu-each').each(function(){
                      if(current !== slug){
                        $(this).removeClass('active');
                      }
                      if($(this).data('slug') === slug){
                        $(this).addClass('active');
                        var current = $(this).data('slug');
                      }
                  });
      
                  $('.fade-box__each').each(function(){
                    if(current !== slug){
                      $(this).removeClass('active');
                    }
                    if($(this).data('slug') === slug){
                      $(this).addClass('active');
                      var current = $(this).data('slug');
                    }
                  });
                });
            });        
          }
        } fadeBox();

        function slickMobile(){
          var gallery = $('.fade-box__gallery-mobile');
          if(gallery.length){
            gallery.each(function(){
              $(this).slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',  
                arrows: false
              });
            });
          }
        } slickMobile();

        function galleryModal(){
          var pics = $('.fade-box__gallery .fade-box__menu-each-img');
          var gallery = $('.gallery-modal');
          var number = 0;

          if(pics.length){
            setTimeout(function(){
              gallery.hide();
              gallery.css("visibility", "visible");
            }, 1000);
            pics.each(function(){
              $(this).click(function(){
                number = parseInt($(this).data('index'));
                gallery.fadeIn();
                $('.document').addClass('active');
                $('.slick-this').slick('slickGoTo', number);
              });
            });

            function remove(){
              gallery.fadeOut();
              $('.document').removeClass('active');
            }

            $('.gallery-modal__background').click(function(){
              remove();
            });

            $('.gallery-cancel').click(function(){
              remove();
            });
          }
        } galleryModal();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
